import React, { Component } from "react";
import Nav from "../components/Nav";
import "../styles/about.css";
import "../styles/artists.css";
import Footer from "./Footer";
import "../styles/contact.css";

export default class About extends Component {
  render() {
    return (
      <div className="home-container">
        <div className="artist-home-container">
          <Nav />

          <div className="contact-container">
            <div className="contact-form-title">
              {" "}
              <h1>What's Good?</h1>
            </div>
            <div className="contact-form-text">
              <p>
                If you're an artist, please note that we're only taking
                submissions from artists located in San Francisco and the
                surrounding areas. If you're submitting other forms of work,
                please include a link to your portfolio.
                <br />
                Thank you!
              </p>
            </div>

            <form method="POST" data-netlify="true" className="form">
              <input type="hidden" name="form-name" value="contact" />

              <div className="fields">
                <div className="contact-info-container">
                  <div className="field">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    ></input>
                  </div>

                  <div className="field">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    ></input>
                  </div>
                </div>
                <div className="field">
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Message"
                    rows="7"
                    type="text"
                  ></textarea>
                </div>
                <div className="field">
                  <div data-netlify-recaptcha="true"></div>
                </div>
                <div className="submit-fields"></div>

                <div className="actions">
                  <input
                    type="submit"
                    value="Send Message"
                    className="button primary"
                  ></input>
                </div>
              </div>
            </form>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
