import React, { Component } from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import "../styles/home.css";
import covers from "../img/dg-cover-art.png";
import Footer from "./Footer";
import KorruptVideo from "../img/dark-video-2.mp4";

export default class Home extends Component {
  render() {
    return (
      <div className="home-container">
      <div>
      
      </div>
        <div className="container-one">
          <div className="home-nav">
            <Nav />
          </div>
          <div className="hero-text-one">
            <h1>Digital Analog</h1>
            <p>
              A bay area Hip Hop/R&B collective fueling a new movement within
              the genre.
            </p>
          </div>
        </div>
        <div className="container">
          <video autoPlay loop muted preload className="video">
            <source src={KorruptVideo} type="video/mp4" />
          </video>
          <div className="hero-text">
            <h3>vtizzel</h3>
            <h1>Korrupt</h1>
            <p>Official Video</p>
            <button className='coming-soon-btn'><a href='https://www.youtube.com/watch?v=PtucHjAr7UQ&lc=Ugy4s7RLeDP6L-kmg214AaABAg' target='blank'>Out Now</a></button>
          </div>
        </div>
        <div className="content-container">
          <div className="content-left"></div>
          <div className="content-right">
            <div className="inner-content">
              <h1>About</h1>
              <p>
                {" "}
                Digital analog is a collective of bay area artists // creatives
                fueling a new generation of hip hop and R&B. We aim to empower
                likeminded individuals to grow without relying on record labels
                and marketing companies. Digital Analog was built with a "do it
                yourself" attitude and is currently in the early stages of
                building a grassroots movement within the genre and beyond.
              </p>
            </div>
          </div>
        </div>

        <div className="artist-container">
          <div className="artist-text-container">
            <div className="artist-text">
              <h1>The Team</h1>
              <p>
                {" "}
                We currently operate as a small team of three but plan to build
                a much larger collective. Although small, our current team of
                artists have skills that include Art Direction/branding, graphic
                design, web development, marketing, and music video production.
              </p>
              <Link to="/artists" className="home-btn">
                View Artists
              </Link>
            </div>
          </div>
          <div className="artist-image">
            <div className="album-covers-container">
              <img src={covers} alt="album-covers" />
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="content-left-two"></div>
          <div className="content-right">
            <div className="inner-content">
              <h1>Contact</h1>
              <p>
                We're currently seeking talented creatives to join the team.
                This includes designers, video directors/editors, hip hop/r&b
                artists, marketing specialists, and social media managers. If
                you're interested, send us an email with your work!
                <br />
                <strong>
                  <i>Disclaimer: This is not a paid opportunity. </i>
                </strong>
              </p>
              <Link to="/contact" className="home-btn-white">
                Contact
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
