import React, { Component } from "react";
import Nav from "./Nav";
import "../styles/artists.css";
import Footer from "./Footer";
import vprofile from "../img/v-profile.png";
import mellayprofile from "../img/Mellay-Studio.jpg";
import kantraprofile from "../img/kantra-profile.png";

export default class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <div className="artist-home-container">
          <Nav />
          <div className="artist-page-container">
            <div className="artist-title-container">
              <h1>Artists</h1>
            </div>

            <div className="bio-container">
              <div className="bio-photo">
                <div>
                  <img src={vprofile} alt="Profile" className="profile-image" />
                </div>
                <div>
                  <h1>Vtizzel</h1>
                  <div>
                    <a
                      href="https://www.instagram.com/p/B43UgwGJqbv/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-instagram fab-artist"></i>
                    </a>
                    <a
                      href="https://twitter.com/vtizzel"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-twitter fab-artist"></i>
                    </a>
                    <a
                      href="https://open.spotify.com/track/0Kl3l6RLGoqrLIl4x20i98?si=vtA56A2USCWpcxg-guRP7g"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-spotify v-spotify"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bio-text">
                <p className="about-bio-text">About</p>
                <p>
                  Born and raised in San Francisco, vtizzel blends a unique
                  delivery with storytelling inspired by hip hop heavy hitters
                  like J Cole , Kendrick Lamar, and Eminem. He started taking
                  music seriously in 2017 and is building a loyal following
                  through energetic performances , raw emotion, and an ability
                  to tackle a variety of topics with ease .
                </p>
              </div>
            </div>
            <div className="bio-container">
              <div className="bio-photo">
                <div>
                  <img
                    src={mellayprofile}
                    alt="Profile"
                    className="profile-image"
                  />
                </div>
                <div>
                  <h1>Mellay the Ghost</h1>
                  <div className="social-icon-container">
                    <a
                      href="https://www.instagram.com/mellaytheghost/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-instagram fab-artist"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/Mellay-100813271338981"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-facebook fab-artist"></i>
                    </a>
                    <a
                      href="https://twitter.com/mellaytheghost"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-twitter fab-artist"></i>
                    </a>
                    <br />
                    <a
                      href="https://open.spotify.com/artist/60ouRLjIwzFd5myMSsGzpK?si=ZntcZJKUQbCFWQYDuv0q1w"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-spotify"></i>
                    </a>
                    <a
                      href="https://music.apple.com/us/artist/mellay/1494050175"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-apple"></i>
                    </a>

                    <a
                      href="https://soundcloud.com/mellaytheghost"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-soundcloud"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bio-text">
                <p className="about-bio-text">About</p>
                <p>
                  Although half of his family was born & raised in San
                  Francisco, Mellay moved to Hawaii before his first birthday
                  and wouldn’t find himself living in the bay area again until
                  2018. With no local scene to draw inspiration from, he
                  developed an innovative sound by blending melodic hooks with a
                  versatile flow reminiscent of Kid Cudi’s early projects. He
                  also produces the majority of his work himself, often using
                  smooth synths and groove-driven drums to support his lyrics.
                </p>
              </div>
            </div>
            <div className="bio-container">
              <div className="bio-photo">
                <div>
                  <img
                    src={kantraprofile}
                    alt="Profile"
                    className="profile-image"
                  />
                </div>
                <div>
                  <div className="kantra-container">
                    <h1>Kantraban</h1>
                    <h1>Kartel</h1>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/kantraban_kartel/?hl=en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-instagram fab-artist"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/kantraban_kartel/?hl=en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-facebook fab-artist"></i>
                    </a>
                    <a
                      href="https://twitter.com/kantrabank"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-twitter fab-artist"></i>
                    </a>
                    <br />
                    <a
                      href="https://open.spotify.com/artist/02e9D8fVbj1ErHmW2OOg7Q?si=CCoer-nTTYGz6N7GBFeqGw"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-spotify"></i>
                    </a>
                    <a
                      href="https://music.apple.com/us/artist/kantraban-kartel/1492050510"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-apple"></i>
                    </a>

                    <a
                      href="https://soundcloud.com/gutierrezbrandon"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-soundcloud"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bio-text">
                <p className="about-bio-text">About</p>
                <p>
                  The Kantraban Kartel is a group of Bay Area producers who
                  focus on creating music that reflect the raw and unfiltered
                  truth found within every artist. Their unique approach to trap
                  inspired melodies and hard hitting drums provide their
                  listeners with strong emotion and style within their work.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
