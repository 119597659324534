import React, { Component } from "react";
import Nav from "../components/Nav";
import "../styles/about.css";
import "../styles/artists.css";
import Footer from "./Footer";

export default class About extends Component {
  render() {
    return (
      <div className="home-container">
      <div className="artist-home-container">
        <Nav />
      <div className='about-showcase'></div>
          <div className="about-container">
            <h1>About</h1>
            <p>
              Digital analog is a collective of bay area artists // creatives
              fueling a new generation of hip hop and R&B. We aim to empower
              likeminded individuals to grow without relying on record labels
              and marketing companies. Digital Analog was built with a "do it
              yourself" attitude and is currently in the early stages of
              building a grassroots movement within the genre and beyond.
              <br/>
              DA was built with the belief that passion and
              discipline are the only ingredients needed for success. Instead of
              waiting for resources, we learned to create them ourselves.
              Instead of searching for labels, we built a collective. We use
              rejection and criticism as motivation to improve and empower
              artists to stay true to themselves when it seems the world is
              against them.
            </p>
            <div className='hashtag'><strong>#ProveThemWrong</strong></div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
