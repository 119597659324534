import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../styles/nav.css";
import logo from "../img/da-logo-white.svg";

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayNav: false
    };
    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
    this.state.displayNav
      ? this.setState({ displayNav: false })
      : this.setState({ displayNav: true });
  }

  render() {
    const showNav = this.state.displayNav ? "show" : "hide";

    return (
      <div>
        <div className="nav-container">
          <div id="Nav">
            <div className="mobile-wide-container">
              <div className="mobile-wide">
                <div className="branding">
                  <Link to="/">
                    <img src={logo} alt="logo"></img>
                  </Link>
                </div>
                <div className="burger" onClick={this.toggleNav}>
                  <div className='line'></div>
                  <div className='line'></div>
                  <div className='line'></div>
                </div>
              </div>
            </div>
            <div className="links">
              <ul>
                <li>
                  <Link className="link" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/artists">
                    Artists
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/about">
                    About
                  </Link>
                </li>
                <li>
                <Link className="link" to="/contact">
                  Contact
                </Link>
              </li>
              </ul>
            </div>

            <div className={`${showNav}`} id='mobileNav'>
              <div className="x">
                <div onClick={this.toggleNav} className='close-container'><h1>X</h1></div>
              </div>
              <div className="res-nav-items">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/artists">Artists</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                  <Link to="/contact">Contact</Link>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
