import React from "react";
import "../styles/footer.css";

export default function Footer() {
  return (
    <footer>
      <div>
        {" "}
        <a href='https://www.instagram.com/digital_analog/' rel="noopener noreferrer" target='_blank'><i className="fab fa-instagram"></i></a>
        <a href='https://www.facebook.com/digitalanalogcollective' rel="noopener noreferrer" target='_blank'><i className="fab fa-facebook"></i></a>
      </div>
      <div>
        {" "}
        <p>&copy; 2020 Digital Analog</p>


      </div>
      <div></div>
    </footer>
  );
}
