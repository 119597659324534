import React, { Component } from "react";
import { Route, HashRouter, Switch } from "react-router-dom";
import Home from "./Home";
import Artists from './Artists';
import About from './About';
import Contact from './Contact';

export default class Routes extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={routerProps => <Home {...routerProps} />}
          />
          <Route
            exact
            path="/artists"
            render={routerProps => <Artists {...routerProps} />}
          />
          <Route
          exact
          path="/about"
          render={routerProps => <About {...routerProps} />}
        />
          <Route
            exact
            path="/contact"
            render={routerProps => <Contact {...routerProps} />}
          />

        </Switch>
      </HashRouter>
    );
  }
}
